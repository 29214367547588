import './search'
import './slider'
import './header'
import './menu'
import './pro_barreau'


require('../less/main.less')



import Vue from 'vue'
import Trainings from './components/Trainings.vue'

Vue.config.productionTip = true;

if(document.getElementById('seminars_list_results')) {
  new Vue({
    render: h => h(Trainings)
  }).$mount('#seminars_list_results');
}

